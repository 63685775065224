const fs = `\
uniform float timez;
uniform float amount;

const float PHI = 1.61803398874989484820459; // Φ = Golden Ratio 

float rand(vec2 co) {
  return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}

float gold_noise(vec2 xy, float seed)
{
    return fract(tan(distance(xy*PHI, xy)*seed)*xy.x);
}


vec4 static_sampleColorz(sampler2D texture, vec2 texCoord, vec2 texSize ) {
    vec4 color = texture2D(texture, texCoord);
    vec2 coord = texCoord * texSize;
    //return color;
    float diff = (rand(coord*fract(timez))-.5) * amount ;
    color.r += diff;
    color.g += diff;
    color.b += diff;
    return color; 

}

vec4 static_sampleColor(sampler2D texture, vec2 texSize, vec2 texCoord) {
  return static_sampleColorz(texture, texCoord, texSize);
}
`;

const uniforms = {
  timez: {value: 0.04, min: 0, max: 10000000000000000},
  amount: {value: 0.5, min: 0, max: 1}
};

export const staticgl = {
  name: 'static',
  uniforms,
  fs,
  passes: [{sampler: true}]
};


