//import logo from './logo.svg';
import {ascii} from './ascii'
import {staticgl} from './static'
import './App.css';
import DeckGL from '@deck.gl/react';
import {TerrainLayer} from '@deck.gl/geo-layers';
import React, { useState, useEffect,useRef } from 'react';
import {StaticMap} from 'react-map-gl';
import {FirstPersonView} from '@deck.gl/core';
import {noise,ink,hexagonalPixelate,dotScreen,colorHalftone} from '@luma.gl/shadertools';
import {PostProcessEffect,LightingEffect,AmbientLight,DirectionalLight} from '@deck.gl/core';
import {_SunLight as SunLight} from '@deck.gl/core';
import useInterval from '@use-it/interval';
import * as interp from 'd3-interpolate';
import * as ease from 'd3-ease';
import dayjs from 'dayjs';

// Viewport settings
const INITIAL_VIEW_STATE = {
  longitude: -112.040725,
  latitude: 33.548247,
  pitch: -10,
  bearing: 107
};

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYXB1bGNpcGhlciIsImEiOiJjams1MDN6MnEwejdjM3J0aHl5aHl0YjBwIn0.W34T-s8ivR-wkDV5MlELWQ';
const HEIGHT_URL='testartino.png'
const DELAY = 60
const TIME_JUMP = .5 // IN MINUTES
const SUN_INTENSITY = 1.3;
const START_TIME = 1469105597000

function precise(x) {
  return Number.parseFloat(x).toPrecision(7);
}

var skyColorScale = interp.interpolateLab("#0b0f2a", "#87ceeb")

function App() {
  const [curTime, setCurTime] = useState(START_TIME);
  const [sunInt, setSunInt] = useState(1.3);
  const [showVida,setShowVida] = useState('hideVid')
  const [showVidb,setShowVidb] = useState('hideVid')
  const [showVidc,setShowVidc] = useState('hideVid')

  useInterval(() => {
    setCurTime((curTimet) => curTime + (TIME_JUMP*60000));
  }, DELAY);

  useEffect(() => {
    let timeObj = dayjs(curTime)
    let h = timeObj.hour()
    //console.log(timeObj)
    let m = timeObj.minute()
    let minutes = h * 60 + m
    //console.log(minutes) 

    let sundown = 1440
    let sunup = 320
    let zenith = 770
    let zenith_end = 820

    let color = []
    if(minutes <sunup || minutes > sundown){  
      setSunInt(0)
    } else{
      if(minutes<zenith){
        setSunInt((((minutes-sunup)/(zenith-sunup))*SUN_INTENSITY))
      } else{
        if(minutes=>zenith && minutes <=zenith_end){
          setSunInt(((1-((minutes-zenith_end)/(sundown-zenith_end)))*SUN_INTENSITY))
        } else{
          setSunInt(SUN_INTENSITY)
        }
      }
    }

  }, [curTime]); // Only re-run the effect if count changes

  const view = new FirstPersonView({id: 'pov', controller: {scrollZoom: false},far: 100000});

  const calcSkyColor = (time) => {
    let timeObj = dayjs(time)
    let h = timeObj.hour()
    let m = timeObj.minute()
    let minutes = h * 60 + m

    let sundown = 1440
    let sunup = 320
    let zenith = 770
    let zenith_end = 820

    let color = []
    if(minutes <sunup || minutes > sundown){
      color = [11,15,42, 255]
    } else{
      if(minutes<zenith){
        if (((minutes-sunup)/(zenith-sunup))>1){
          console.log(((minutes-sunup)/(zenith-sunup)))
        }
        color = skyColorScale( ((minutes-sunup)/(zenith-sunup)) )
        color = color.replace(/[^\d,]/g, '').split(',')
        color = color.map(c => parseInt(c))
        color[3] = 255
      } else{
        if(minutes>=zenith && minutes <=zenith_end){
          color = [135,206,235,255]
        } else{
          if((1- ((minutes-zenith_end)/(sundown-zenith_end)) ) > 1){
            console.log((1- ((minutes-zenith_end)/(sundown-zenith_end)) ))
            console.log(minutes)
            console.log(zenith_end)
            console.log(sundown)
            console.log((minutes-zenith_end))
            console.log((sundown-zenith_end))
          }
          color = skyColorScale(  (1- ((minutes-zenith_end)/(sundown-zenith_end)) ) )
          color = color.replace(/[^\d,]/g, '').split(',')
          color = color.map(c => parseInt(c))
          color[3] = 255
        }
      }
    }
    return color
  }

  const layer = new TerrainLayer({
    elevationDecoder: {
      rScaler: 4,
      gScaler: 0,
      bScaler: 0,
      offset: -235
    },
    meshMaxError: 4,
    // Digital elevation model from https://www.usgs.gov/
    elevationData: HEIGHT_URL,
    texture: null, //'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain-mask.png',
    color: [149,132,118],
    //wireframe:true,
    material: {
      ambient: 5,
      diffuse: 1,
      shininess:0.1,
      specularColor: [84,70,61 ]
    },
    bounds: [-112.123, 33.510,-112.000, 33.633 ],
  });

  const sunLight = new SunLight({
    timestamp: curTime, 
    color: [150, 124, 87],
    intensity: sunInt
  });

  const ambientLight= new AmbientLight({
    color: [49, 48, 56],
    intensity: .2
  });

  const directionalLight= new DirectionalLight({
    color: [128, 128, 0],
    intensity: 0.1,
    direction: [0, -100, -100]
  });

  const lightingEffect = new LightingEffect({sunLight,ambientLight,directionalLight});

  const postProcessEffect = new PostProcessEffect(staticgl, {
    amount: 0.1,
    timez: precise(((curTime-START_TIME)/START_TIME)*10000) > 0 ? Number(precise(((curTime-START_TIME)/START_TIME)*10000)) : 1.0001
  });

  const postProcessEffect2 = new PostProcessEffect(noise, {
    amount:0.2
  });

  return (
    <div className="App">
      <div className={showVida}>
        <video className="videoh" autoPlay muted loop width="100%" height="100%">
          <source src='whosup.webm' type='video/webm' />
        </video>
      </div>
      <div className={showVidb}>
        <video className="videoh" autoPlay muted loop width="100%" height="100%">
          <source src='mcvs.webm' type='video/webm' />
        </video>
      </div>
      <div className={showVidc}>
        <video className="videoh" autoPlay muted loop width="100%" height="100%">
          <source src='atlas.webm' type='video/webm' />
        </video>
      </div>

      <DeckGL 
        initialViewState={INITIAL_VIEW_STATE} 
        layers={[layer]} 
        views={view}
        parameters={{
          clearColor: calcSkyColor(curTime).map(x=> x/255)
          }}
        effects={[postProcessEffect,lightingEffect]}>
      </DeckGL>
        <div className="storypanel">
          <div className="header">  THE SUNS TIRADE </div>
          <div className="projectList">
            <a href="https://whosup.thesunstirade.com">
            <div className="project" 
                  onMouseEnter={() => setShowVida('showVid')}
                  onMouseLeave={() => setShowVida('hideVid')}
            > 
              <div className="projectHeader">Who's Up?</div>
              <div className="projectBody">
              <div className="projectBodyText">A first of it's kind open source dataset of processed FCC political ad buy filings, and accompanying dashboard.</div>
              </div>
            </div>
            </a>
            <a href="https://mcvs.thesunstirade.com">
            <div className="project"
              onMouseEnter={() => setShowVidb('showVid')}
              onMouseLeave={() => setShowVidb('hideVid')}> 
              <div className="projectHeader">Maricopa Remembers</div>
              <div className="projectBody">
                <div className="projectBodyText">
                A visual exploration of the 2016 Maricopa County Presidential Preference Election debacle, complete with a full simulation of polling locations on election day.
                </div>
              </div>
            </div>
            </a>
            <a href="https://atlas.thesunstirade.com">
            <div className="project last"
                onMouseEnter={() => setShowVidc('showVid')}
                onMouseLeave={() => setShowVidc('hideVid')}> 
              <div className="projectHeader">Atlas</div>
              <div className="projectBody">
              <div className="projectBodyText">
                An interactive tool to generate precinct-level and up maps of any election that occurred in Arizona in 2016 and 2018.
                </div>
              </div>
            </div>
            </a>
          </div>
        </div>
    </div>
  );
}

export default App;

/*


function App({viewState}) {
  const layer = new TerrainLayer({
    elevationDecoder: {
      rScaler: 2,
      gScaler: 0,
      bScaler: 0,
      offset: 0
    },
    // Digital elevation model from https://www.usgs.gov/
    elevationData: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain.png',
    texture: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain-mask.png',
    bounds: [-122.5233, 37.6493, -122.3566, 37.8159],
  });

  return <DeckGL viewState={viewState} layers={[layer]} />;
}
*/